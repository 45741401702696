import { tv } from '@/utils/styles';

export interface BadgeProps {
  variant?: 'light' | 'dark' | 'negative' | 'negativeLight' | 'positive' | 'positiveLight' | 'alert' | 'bold';
  size?: 'sm' | 'md' | 'lg';
  children: string | React.ReactNode;
  className?: string;
}

const Badge = ({ children, variant = 'light', size = 'sm', className }: BadgeProps) => {
  const { base } = styles({ variant, size });
  return <span className={base({ className })}>{children}</span>;
};

const styles = tv({
  slots: {
    base: 'relative inline-flex items-center rounded px-1 text-xs leading-normal',
  },
  variants: {
    variant: {
      light: 'bg-grey-200 text-grey-800',
      dark: 'bg-grey-700 text-white',
      negative: 'bg-error text-white',
      negativeLight: 'bg-error/10 text-error',
      positive: 'bg-green-300 text-grey-900',
      positiveLight: 'bg-green-50 text-green-500',
      alert: 'bg-yellowMid text-grey-900',
      bold: 'rounded-sm bg-grey-100 px-1.5 py-1 font-semibold text-text-primary',
    },
    size: {
      sm: 'text-xs',
      md: 'text-baseSm font-medium',
      lg: 'text-base font-medium',
    },
  },
});

export { Badge };
